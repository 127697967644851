<template>
  <div @click="$emit('click', $event)" class="fs-3 lh-sm">
    {{ subtitle }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSubtitle',
  props: {
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>
